import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Maybe,
  TixCompanyAddress,
  TixPhoneNumber,
  TixVenue,
  TixVenueAddress
} from '@tix/data-access';
import { BuyerService } from 'libs/event-buyer/components/src/lib/services/buyer.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

function firstOrNull<T>(array: Array<T> = []): T | null {
  return array[0] ?? null;
}

@Component({
  selector: 'tix-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class TixPublicHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() venueInfo: Maybe<TixVenue>;
  @Input() venueId: string;

  currentRoute: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private buyerService: BuyerService
  ) {}

  showClearFilters$ = this.buyerService.canClearFilters$;
  showListView$ = this.buyerService.showListViewBtn$;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes, 'changes');
  }

  get showTestMode(): boolean {
    return (
      !!this.venueInfo?.state && this.venueInfo.state.toLowerCase() !== 'active'
    );
  }

  get phoneNumbers(): TixPhoneNumber[] {
    if (
      this.venueInfo?.phoneNumbers.length &&
      this.venueInfo?.phoneNumbers?.[0].phoneNumber?.sameAsCompany
    ) {
      return (
        this.venueInfo?.company?.phoneNumbers?.map(
          p => p.phoneNumber as TixPhoneNumber
        ) || []
      );
    }
    return (
      this.venueInfo?.phoneNumbers?.map(p => p.phoneNumber as TixPhoneNumber) ||
      []
    );
  }

  get venueUrl(): string {
    const url = this.venueInfo && this.venueInfo.url ? this.venueInfo.url : '';
    if (!url) return '';
    if (url.includes('http')) return url;
    return `http://${url}`;
  }

  formatPhoneNumber(phoneNumber: Maybe<string>) {
    if (!phoneNumber) return '';

    return '1.' + phoneNumber.split('-').join('.');
  }

  getPhoneNumberHref(phoneNumber: Maybe<string>) {
    if (!phoneNumber) return '';

    return 'tel:' + phoneNumber.split('-').join('');
  }

  get venueAddress(): Maybe<TixVenueAddress> {
    return firstOrNull(this.venueInfo?.addresses);
  }

  get companyAddress(): Maybe<TixCompanyAddress> {
    return firstOrNull(this.venueInfo?.company?.addresses);
  }

  get venueMedias(): string | undefined {
    if (
      this.venueInfo?.mediaFiles === null ||
      !this.venueInfo?.mediaFiles?.length
    ) {
      return '';
    }
    return this.venueInfo?.mediaFiles[0]?.mediaFile?.location;
  }

  get companyMedias(): string | undefined {
    if (
      this.venueInfo?.company?.mediaFiles === null ||
      !this.venueInfo?.company?.mediaFiles?.length
    ) {
      return '';
    }
    return this.venueInfo?.company?.mediaFiles[0]?.mediaFile?.location;
  }

  formattedVenueAddress(): string {
    if (this.venueAddress?.address) {
      return [
        this.venueAddress?.address?.sameAsCompany
          ? `${this.companyAddress?.address?.streetAddress ?? ''},
          ${this.companyAddress?.address?.city ?? ''},
            ${this.companyAddress?.address?.stateProvince ?? ''} ${
              this.companyAddress?.address?.postalCode ?? ''
            }`.trim()
          : `${this.venueAddress?.address?.streetAddress ?? ''} ,
            ${this.venueAddress?.address?.city ?? ''},
            ${this.venueAddress?.address?.stateProvince ?? ''} ${
              this.venueAddress?.address?.postalCode ?? ''
            }`.trim()
      ]
        .filter(v => !!v)
        .join(', ');
    } else {
      return '';
    }
  }

  getVenueAddressOnMapURL() {
    if (!this.formattedVenueAddress()) return null;
    return `https://www.google.com/maps/search/?api=1&query=${this.formattedVenueAddress()}`;
  }

  clearFilters(): void {
    this.buyerService.clearFilters();
  }
  navigateToListView(): void {
    const currentUrl = this.router.url;
    const updatedUrl = currentUrl.replace('/calendar-view', '');

    // Navigate to the updated URL
    this.router.navigateByUrl(updatedUrl);
  }
  ngOnDestroy(): void {}
}
