<tix-card>
  <tix-card-header>
    <tix-card-title>Profile</tix-card-title>
  </tix-card-header>
  <tix-card-content>
    <form [formGroup]="profileForm">
      <div class="profile-content-layout">
        <mat-form-field>
          <mat-label>First name</mat-label>
          <input
            [readonly]="!!selectedExistingUser"
            matInput
            type="text"
            formControlName="contactFirstName"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Last name</mat-label>
          <input
            [readonly]="!!selectedExistingUser"
            matInput
            type="text"
            formControlName="contactLastName"
          />
        </mat-form-field>
        <mat-form-field class="position-relative col-span-2">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="text"
            formControlName="contactEmailAddress"
            role="presentation"
            autocomplete="off"
            [readonly]="isEditMode || !!selectedExistingUser"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let user of emailList$ | async"
              (onSelectionChange)="getUserInfoByEmail(user)"
              >{{ user.emailAddress }}</mat-option
            >
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <ng-container *ngIf="!isEditMode">
        <div class="flex">
          <tix-card-title class="mb-1">Permissions</tix-card-title>
          <tix-icon
            matTooltip="Click for more info"
            (click)="openHelpDialog()"
            class="cursor-pointer ml-2 transform -scale-x-100"
            svgIcon="ticket-info-icon"
          ></tix-icon>
        </div>

        <div class="permissions-content-layout">
          <div
            class="permission-box-layout"
            *ngFor="let item of userRolesList$ | async as roleList"
          >
            <tix-checkbox
              [labelPosition]="'before'"
              [value]="item.roleId"
              (change)="onChange($event, item)"
              [disabled]="isEventAdminSelected && item.role !== 'event_admin'"
              [checked]="profileForm.value.permissions.includes(item.roleId)"
            >
              {{ item?.title }}
            </tix-checkbox>
          </div>
        </div>
      </ng-container>
    </form>
  </tix-card-content>

  <tix-card-actions align="end">
    <div class="flex flex-col-reverse justify-end md:flex-row">
      <button
        tix-flat-border-button
        class="mt-3 mr-0 md:mr-5 md:mt-0"
        *ngIf="(isAdmin$ | async) && currentStaffId !== (userId$ | async)"
        color="danger"
        (click)="handleDelete()"
      >
        Delete Staff
        <span
          class="ml-1"
          *tixBtnLoading="isloading || (isDeleting$ | async)"
        ></span>
      </button>
      <button tix-button (click)="onSave()" [disabled]="!isSaveButtonDisabled">
        Save
        <span
          class="ml-1"
          *tixBtnLoading="isloading || (isSaving$ | async)"
        ></span>
      </button>
    </div>
  </tix-card-actions>
</tix-card>
