import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tix-help-text-dialog',
  templateUrl: './help-text-dialog.component.html',
  styleUrls: ['./help-text-dialog.component.scss']
})
export class TixHelpTextDialogComponent implements OnInit {
  @Input() roleList: any[];

  constructor() {}

  ngOnInit(): void {}
}
