<div class="wrapper">
  <h2 mat-dialog-title>Roles Descriptions:</h2>
  <div mat-dialog-content>
    <ul>
      <li *ngFor="let role of roleList">
        <b>{{ role.title }}:</b> {{ role.description }}
      </li>
    </ul>
    <div class="mt-5">
      <h2 class="font-bold text-lg">Role Selection Information:</h2>
      <p>
        All roles can be selected together. When two or three roles are
        selected, at the same time their permissions are shared. <br />
        The <b>Event Administrator</b> can only be selected on its own.
      </p>
    </div>
  </div>
</div>
