<tix-card>
  <tix-card-header>
    <tix-icon
      matTooltip="Click for more info"
      (click)="openHelpDialog()"
      class="cursor-pointer ml-2 transform -scale-x-100"
      svgIcon="ticket-info-icon"
    ></tix-icon>
    <tix-card-title>Roles</tix-card-title>
  </tix-card-header>
  <tix-card-content *ngIf="userRolesForm">
    <form class="profile-content-layout" [formGroup]="userRolesForm">
      <div class="permission-box-layout" *ngFor="let item of roleList">
        <tix-checkbox
          [labelPosition]="'before'"
          [formControlName]="item.key"
          value="test"
          (change)="onChange($event, item)"
          [disabled]="keepDisableSaveBtn"
        >
          {{ item.title }}
        </tix-checkbox>
      </div>
    </form>
  </tix-card-content>
  <tix-card-actions align="end">
    <button
      tix-button
      [disabled]="
        userRolesForm.pristine ||
        isloading ||
        userRolesForm.invalid ||
        (isSaving$ | async)
      "
      (click)="onSave()"
    >
      Save
      <span
        class="ml-1"
        *tixBtnLoading="isloading || (isSaving$ | async)"
      ></span>
    </button>
  </tix-card-actions>
</tix-card>
