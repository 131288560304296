import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {
  public _previousMonthClick = new Subject();
  previousMonthClick$ = this._previousMonthClick.asObservable();

  public _nextMonthClick = new Subject();
  nextMonthClick$ = this._nextMonthClick.asObservable();

  private canClearFiltersSubject = new BehaviorSubject<boolean>(false);
  canClearFilters$ = this.canClearFiltersSubject.asObservable();

  private showListViewBtnSubject = new BehaviorSubject<boolean>(false);
  showListViewBtn$ = this.showListViewBtnSubject.asObservable();

  private onClearFilters: Function;

  setShowListViewBtn(value: boolean) {
    this.showListViewBtnSubject.next(value);
  }

  setCanClearFilters(value: boolean) {
    this.canClearFiltersSubject.next(value);
  }

  setClearFiltersListener(listener: Function) {
    this.onClearFilters = listener;
  }

  clearFilters() {
    if (this.onClearFilters) this.onClearFilters();
  }

  constructor() {}
}
