import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  TixDiscountHasPasscodeGQL,
  TixGetDiscountInfoGQL,
  TixGetDiscountInfoQuery,
  TixIsValidDiscountCodeGQL,
  TixTicketConfig
} from '@tix/data-access';

import { TixDialog } from '@tix/shared/ui/components';
import { TixCheckDiscountPasscodeComponent } from '../check-discount-passcode/check-discount-passcode.component';
import { TicketConfiguration } from '@tix/event-buyer/state';
import { AmplitudeService } from 'amplitude.service';

@Component({
  selector: 'tix-discountcode-input',
  templateUrl: './discountcode-input.component.html',
  styleUrls: ['./discountcode-input.component.scss']
})
export class TixDiscountCodeInputComponent {
  @Input()
  eventId: string;

  @Input()
  venueId: string;

  @Input()
  companyId: string;

  @Output() updateError = new EventEmitter<string>();

  @Output()
  applyDiscount = new EventEmitter<TixGetDiscountInfoQuery['Discount'][0]>();

  @Output()
  invalidDiscountApply = new EventEmitter<boolean>();

  constructor(
    private snackbar: MatSnackBar,
    private dialog: TixDialog,
    private hasPasscodeQuery: TixDiscountHasPasscodeGQL,
    private isValidDiscountCodeQuery: TixIsValidDiscountCodeGQL,
    private getDiscountInfoQuery: TixGetDiscountInfoGQL,
    private amplitudeService: AmplitudeService
  ) {}

  discountControl = new FormControl('', Validators.required);

  isLoading = false;
  @Input() isApplied = false;

  @Input() ticketsSelectedCount = 0;

  async isValidDiscountCode(discountCode: string) {
    const res = await this.isValidDiscountCodeQuery
      .fetch(
        {
          data: {
            code: discountCode,
            eventId: this.eventId,
            venueId: this.venueId,
            companyId: this.companyId
          }
        },
        { fetchPolicy: 'no-cache' }
      )
      .toPromise();

    return !!res.data.isValidDiscountCode;
  }

  async handleSubmit(event: SubmitEvent) {
    event.preventDefault();

    const discountCode = this.discountControl.value;

    if (!discountCode) return;

    this.isLoading = true;
    this.invalidDiscountApply.emit(false);

    try {
      const isValid = await this.isValidDiscountCode(discountCode);
      if (!isValid) {
        this.invalidDiscountApply.emit(true);
        this.amplitudeService.trackEvent('Invalid Discount Code');
      }
      if (isValid) {
        const requiresPasscode = await this.checkRequiresPasscode(discountCode);

        if (requiresPasscode) {
          const correctPasscode = await this.askForPasscode();
          if (correctPasscode) {
            this.applySuccess(discountCode);
          }
        } else {
          this.applySuccess(discountCode);
          this.amplitudeService.trackEvent('Successful Discount Code');
        }
      }
    } catch (e) {
      console.error(e);
      this.snackbar.open('Something went wrong!');
    }

    this.isLoading = false;
  }

  async checkRequiresPasscode(discountCode: string) {
    const res = await this.hasPasscodeQuery
      .fetch(
        {
          data: {
            code: discountCode,
            companyId: this.companyId,
            eventId: this.eventId,
            venueId: this.venueId
          }
        },
        { fetchPolicy: 'no-cache' }
      )
      .toPromise();

    return !!res.data.hasPasscode;
  }

  async applySuccess(discountCode: string) {
    this.updateError.emit('');
    const res = await this.getDiscountInfoQuery
      .fetch(
        {
          code: discountCode,
          eventInstanceId: this.eventId,
          venueId: this.venueId
        },
        { fetchPolicy: 'no-cache' }
      )
      .toPromise();

    if (!res.data.Discount.length) {
      this.updateError.emit('No discount found');
      throw Error;
    }
    switch (res.data.Discount[0].groupDiscountType) {
      case 'A Minimum Order Of':
        if (
          this.ticketsSelectedCount < res.data.Discount[0].groupDiscountAmount
        ) {
          this.updateError.emit(
            `This discount code is not applicable for orders of less than ${
              res.data.Discount[0].groupDiscountAmount
            } ${
              res.data.Discount[0].groupDiscountAmount === 1
                ? ' ticket'
                : ' tickets'
            }.`
          );
          throw Error;
        }
        break;
      case 'A Maximum Order Of':
        if (
          this.ticketsSelectedCount > res.data.Discount[0].groupDiscountAmount
        ) {
          this.updateError.emit(
            `This discount code is not applicable for orders of more than ${
              res.data.Discount[0].groupDiscountAmount
            } ${
              res.data.Discount[0].groupDiscountAmount === 1
                ? ' ticket'
                : ' tickets'
            }.`
          );
          throw Error;
        }
        break;
      case 'An Order of Exactly':
        if (
          this.ticketsSelectedCount !== res.data.Discount[0].groupDiscountAmount
        ) {
          this.updateError.emit(
            `The discount code must be used for exactly ${
              res.data.Discount[0].groupDiscountAmount
            } ${
              res.data.Discount[0].groupDiscountAmount === 1
                ? ' ticket'
                : ' tickets'
            }.`
          );
          throw Error;
        }
        break;
    }

    this.applyDiscount.emit(res.data.Discount[0]);

    this.snackbar.open('Discount applied successfully!');
    this.isApplied = true;
  }

  askForPasscode(): Promise<boolean> {
    return new Promise(resolve => {
      const dialog = this.dialog.open(TixCheckDiscountPasscodeComponent);
      dialog.componentInstance.eventId = this.eventId;
      dialog.componentInstance.venueId = this.venueId;
      dialog.componentInstance.companyId = this.companyId;
      dialog.componentInstance.discountCode = this.discountControl.value;

      dialog.componentInstance.passcodeVerified.subscribe(valid => {
        resolve(valid);
        dialog.close();
      });

      dialog.afterClosed().subscribe(() => resolve(false));
    });
  }
}
