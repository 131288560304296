import * as moment from 'moment';

export const addDateTimezoneOffset = (date: any): Date | undefined => {
  if (!date) return undefined;

  const diff = new Date(date).getTimezoneOffset();
  const d = new Date(date);
  d.setTime(d.getTime() + diff * 60 * 1000);
  return d;
};

// adding hours and minutes to the date
export const addDateTimezoneOffsetV2 = (
  date: any,
  time: any
): [any | null, any | null] => {
  if (date === null || time == null) {
    return [date, time];
  }
  console.log('-----start function -----------');
  console.log('date ', date, time);

  const diff = new Date(date).getTimezoneOffset();

  console.log('before date', date);
  const d = new Date(date.replace(/-/g, '/'));
  d.setHours(0, 0, 0);

  console.log('after date', d);
  if (time) {
    d.setTime(
      d.getTime() +
        time.split(':')[0] * 60 * 60 * 1000 +
        time.split(':')[1] * 60 * 1000
    );
  }

  console.log('before add', [
    d,
    `${d.getHours()}`.padStart(2, '0') +
      ':' +
      `${d.getMinutes()}`.padStart(2, '0')
  ]);
  d.setTime(d.getTime() - diff * 60 * 1000);
  console.log('after add', [
    d,
    `${d.getHours()}`.padStart(2, '0') +
      ':' +
      `${d.getMinutes()}`.padStart(2, '0')
  ]);
  console.log('-----------end function------');
  return [
    d,
    `${d.getHours()}`.padStart(2, '0') +
      ':' +
      `${d.getMinutes()}`.padStart(2, '0')
  ];
};

export const removeDateTimezoneOffsetV2 = (
  date: any,
  time: any
): [any | null, any | null] => {
  if (date === null || time == null) {
    return [null, null];
  }
  const diff = new Date(date).getTimezoneOffset();
  const d = new Date(date);
  d.setHours(0);
  d.setMinutes(0);
  if (time) {
    d.setTime(
      d.getTime() +
        time.split(':')[0] * 60 * 60 * 1000 +
        time.split(':')[1] * 60 * 1000
    );
  }
  const temp = new Date(d);

  d.setTime(d.getTime() + diff * 60 * 1000);
  console.log('remove', [
    temp,
    `${d.getHours()}`.padStart(2, '0') +
      ':' +
      `${d.getMinutes()}`.padStart(2, '0')
  ]);
  return [
    temp,
    `${d.getHours()}`.padStart(2, '0') +
      ':' +
      `${d.getMinutes()}`.padStart(2, '0')
  ];
};

export const removeDateTimezoneOffset = (date: any): Date => {
  const diff = new Date(date).getTimezoneOffset();
  const d = new Date(date);
  d.setTime(d.getTime() - diff * 60 * 1000);
  return d;
};

export const getFirstAndLastDateOfMonth = (
  date: Date
): { startDate: string; endDate: string } => {
  const year = date.getFullYear();
  const month = date.getMonth();

  const startDate = new Date(year, month, 1);

  const endDate = new Date(year, month + 1, 0);
  console.log('start date', startDate, endDate);
  return {
    startDate: `${startDate}`,
    endDate: `${endDate}`
  };
};

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth();

  const formattedDate = new Date(year, month, 1);

  return `${formattedDate}`;
};
