import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import { environment } from 'apps/purchasing-portal/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  constructor() {
    if (environment.production) {
      this.initializeAmplitude();
    }
  }

  initializeAmplitude() {
    console.log('Initializing Amplitude...');
    try {
      amplitude
        .init('1884b554aeae7a18ac58da135f509a39', { autocapture: true })
        .promise.then(() => {
          console.log('Amplitude initialized successfully');
        })
        .catch((error: any) => {
          console.error('Error in Amplitude init promise:', error);
        });
    } catch (error) {
      console.error('Amplitude initialization failed:', error);
    }
  }

  trackEvent(eventName: string, eventData?: any) {
    amplitude
      .track(eventName, eventData)
      .promise.then((result: any) => {
        console.log('Event tracked successfully:', result);
      })
      .catch((error: any) => {
        console.error('Error tracking event:', error);
      });
  }
}
