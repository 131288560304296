import { PortalEvent } from '@tix/event-buyer/services';
import { Moment } from 'moment';

export const showTooltipOnTheLeft = (eventDate: Date): boolean => {
  const day = eventDate.getDay();

  if (day === 0 || day === 1) return false;
  return true;
};

export const isEventAlreadyPassed = (date: Date) => {
  const todayDate = new Date();
  if (todayDate > date) {
    return true;
  }
  return false;
};

export interface ExtendedProps {
  portalEvent: PortalEvent;
  eventImage: string;
  minTicketPrice: string;
  eventInstanceId: string;
  doorsOpen: string;
  rawDate: Moment;
  startTime: string;
}

export interface EventObject {
  title: string;
  extendedProps: ExtendedProps;
  start: Moment;
}
